import lookupValueServices from '../../../services/notisphere/lookup-value'
import LooValModal from './LooValModal'

export default {
    components: {
        LooValModal
    },
    data() {
        return {
            total: 0,
            items: [],
            searchQuery: {},
            header: [
                {
                    label: 'Lookup Name',
                    name: 'name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Value',
                    name: 'value_preview',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Description',
                    name: 'description',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Last Update',
                    name: 'last_change_at',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                }
            ],
            looValModalKey: 0,
        }
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        updateLooVal(payload) {
            this.looValModalKey += 1
            this.$nextTick(()=>{
                this.$refs.looValModal.forceRerenderUpdate(payload.name)
            })
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            lookupValueServices.findLookupValue({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null
            }).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
    }
}