import { http, param } from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const lookupValueServices = {
    findLookupValue(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' }
        ]
        return http.get(`${API_ROOT}/notisphere/lookup-values${param(arr)}`).then(resp => {
            return resp
        })
    },
    getLookupValue(name) {
        return http.get(`${API_ROOT}/notisphere/lookup-values/${name}`).then(resp => {
            return resp
        })
    },
    updateLookupValue(payload) {
        return http.put(`${API_ROOT}/notisphere/lookup-values/${payload.name}`, payload).then(resp => {
            return resp
        })
    },
}

export default lookupValueServices
